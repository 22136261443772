import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { inputLabel, mb4 } from 'assets/soter-flex-styles';
import { dateSelect } from 'assets/jss/components/addDayTasksDialog';
import { flatten } from 'lodash';
import { useTranslation } from 'react-i18next';
import _sortBy from 'lodash/sortBy';
import AddNewTaskForm from './AddNewTaskForm';
import TaskList from './TaskList/TaskList';
import {
  getClickOnChartTime, getTimeOptions, getTimeValues, isTaskTimeValid,
} from './utils';
import { TASK_PROFILE_EDIT, USER_TASK_ADDED } from '../../../constants/analyticsEvents';
import { editedTaskSelector, isLoadingSelector, taskTitlesSelector } from '../userTasksStore/selectors';
import * as types from '../userTasksStore/actionTypes';
import {
  createUserTaskJobTitleCreateTask,
  createUserTaskJobTitleEditTask,
  getUserTaskByWorksessionId,
  getUserTasksTitles,
  removeUserTask,
  setEditedTask,
  setStartTime
} from '../userTasksStore/actions';
import { getUser } from '../userStore/actions';
import { createEventNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import Select from '../ProgramContainer/components/Select';

const AddDayTasksDialog = ({
  hazardsByDay,
  userId,
  userTimezone: _userTimezone,
  dates,
  workSessionId,
  setWorkSessionId,
}) => {
  const userTimezone = _userTimezone || moment.tz.guess();

  const dispatch = useDispatch();

  const clickOnChartTime = useSelector((state) => state.userTasks.startTime);

  const tasksAreLoading = useSelector(isLoadingSelector);

  const hazardsByTask = hazardsByDay.find(
    (dailyReport) => dailyReport.workSessionId === +workSessionId,
  )?.hazardsByTaskList;

  const preparedTasks = _sortBy(hazardsByTask, 'timeStart');
  useEffect(() => {
    if (!tasksAreLoading) {
      dispatch({
        type: types.GET_TASKS_BY_WORKSESSION_SUCCESS,
        isLoading: false,
        tasks: preparedTasks,
      });
    }
  }, [hazardsByTask]);

  const tasksFromStore = useSelector((state) => state.userTasks.tasks) || [];

  const tasks = useMemo(() => _sortBy(tasksFromStore, ['timeStart']), [tasksFromStore]);

  const [currentWorksessionId, setCurrentWorksessionId] = useState(
    Number(workSessionId),
  );

  const [shouldBeValidated, setShouldBeValidated] = useState(false);

  useEffect(() => {
    dispatch(getUserTasksTitles(userId));
  }, [userId]);

  useEffect(() => {
    dispatch(getUserTaskByWorksessionId(currentWorksessionId));
  }, [currentWorksessionId]);

  const worksessionDate = useMemo(
    () => dates.find(({ id }) => id === currentWorksessionId),
    [dates, currentWorksessionId],
  );

  const date = new Date(worksessionDate?.date);

  const taskTitles = useSelector(taskTitlesSelector);

  const handleSessionIdChange = (value) => {
    dispatch(setEditedTask(false));
    setCurrentWorksessionId(Number(value));
    setWorkSessionId(Number(value));
  };

  let timeOptions = getTimeOptions(
    worksessionDate?.timeStart,
    worksessionDate?.timeEnd,
    userTimezone,
  );

  const timeValuesForTasks = tasks?.map((i) => getTimeOptions(i.timeStart, i.timeEnd, userTimezone));

  const timeOptionsForClick = getTimeValues(
    timeOptions,
    flatten(timeValuesForTasks),
  );

  const initialValues = {
    taskName: '',
    timeStart: clickOnChartTime
      ? getClickOnChartTime(timeOptionsForClick, clickOnChartTime)?.value
      : getTimeValues(timeOptions, flatten(timeValuesForTasks))[0]?.value,
    timeEnd: '',
  };

  const editedTask = useSelector(editedTaskSelector) || initialValues;

  timeOptions = editedTask.id
    ? timeOptions
    : getTimeValues(timeOptions, flatten(timeValuesForTasks));

  const endTimeOptions = useMemo(
    () => timeOptions.filter((option) => option.value > editedTask.timeStart),
    [timeOptions, editedTask.timeStart],
  );

  const dispatchSetEditedTask = (task) => {
    dispatch(setEditedTask(task));
  };

  useEffect(() => {
    dispatchSetEditedTask(initialValues);
  }, [currentWorksessionId]);

  useEffect(() => {
    if (editedTask.id) {
      dispatch(setStartTime(false));
      dispatchSetEditedTask({
        ...editedTask,
      });
    } else {
      dispatchSetEditedTask({
        ...editedTask,
        timeStart: initialValues.timeStart,
      });
    }
  }, [initialValues.timeStart]);

  const handleFormFieldChange = (name, value) => {
    dispatchSetEditedTask({
      ...editedTask,
      [name]: value,
    });
  };

  const { taskName, title, timeStart, timeEnd } = editedTask;

  const handleEditTask = (id) => {
    const task = tasks.find((el) => el.id === id);
    dispatchSetEditedTask(task);
  };

  const handleCancelEditTask = () => {
    dispatchSetEditedTask(initialValues);
  };

  const handleRemoveTask = (taskId) => {
    if (taskId === editedTask.id) {
      dispatchSetEditedTask(initialValues);
    }
    dispatch(removeUserTask(taskId));
    dispatch(getUser(userId));
  };

  const isValidTaskTime = useMemo(
    () => isTaskTimeValid(
      tasks?.filter((task) => task.id !== editedTask.id),
      timeStart,
      timeEnd,
      date,
    ),
    [tasks, editedTask],
  );

  const isTaskNameFilled = editedTask.taskName?.trim() !== '';
  const isTimeEndBiggerThanTimeStart
    = editedTask.timeEnd > editedTask.timeStart;
  const isValid
    = isTaskNameFilled && isValidTaskTime && isTimeEndBiggerThanTimeStart;

  const validationProps = {
    isTaskNameFilled,
    isValidTaskTime,
    isTimeEndBiggerThanTimeStart,
  };

  const handleSaveEditedTask = () => {
    if (isValid) {
      setShouldBeValidated(false);
      const taskInfo = {
        taskId: editedTask.id,
        userId,
        workSessionId: currentWorksessionId,
        taskName: taskName || title,
        timeStart,
        timeEnd,
      };
      window.logEvent(TASK_PROFILE_EDIT, {
        current_fields: taskInfo,
      });
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(TASK_PROFILE_EDIT));
      }
      dispatch(createUserTaskJobTitleEditTask(taskInfo));
    } else {
      setShouldBeValidated(true);
    }
  };

  const handleAddNewTask = () => {
    if (isValid) {
      window.logEvent(USER_TASK_ADDED);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(USER_TASK_ADDED));
      }
      dispatch(setStartTime(false));
      setShouldBeValidated(false);
      dispatch(
        createUserTaskJobTitleCreateTask(
          userId,
          currentWorksessionId,
          taskName,
          timeStart,
          timeEnd,
        ),
      );
    } else {
      setShouldBeValidated(true);
    }
  };
  const { t } = useTranslation();

  return (
    <div style={{ position: 'relative' }}>
      <h3 className={mb4}>{t('WEARABLES.USER_PAGE.TASK_LIST')}</h3>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1.2rem',
        }}
      >
        <div className={inputLabel} style={{ marginBottom: 0 }}>
          {`${t(
            'WEARABLES.USER_PAGE.WORK_SESSION',
          )}: `}
        </div>
        <Select
          className={dateSelect}
          id="currentWorksession"
          data={dates}
          optionMapFunction={(element) => (
            <option key={element.id} value={element.id}>
              {element.date}
            </option>
          )}
          emptyOptionsText={t('WEARABLES.USER_PAGE.NO_DAYS')}
          defaultValue={String(currentWorksessionId)}
          setValue={handleSessionIdChange}
        />
      </div>
      <TaskList
        isLoading={tasksAreLoading}
        tasks={tasks}
        editedTaskId={editedTask.id}
        timeZone={userTimezone}
        worksessionStartTime={worksessionDate?.timeStart}
        onEditTask={handleEditTask}
        onRemoveTask={handleRemoveTask}
      />
      <AddNewTaskForm
        values={editedTask}
        taskTitles={taskTitles}
        isAddTaskBtnDisabled={shouldBeValidated && !isValid}
        isEditMode={!!editedTask.id}
        timeOptions={timeOptions}
        endTimeOptions={endTimeOptions}
        shouldBeValidated={shouldBeValidated}
        validationProps={validationProps}
        onFieldChange={handleFormFieldChange}
        onSaveEditedTask={handleSaveEditedTask}
        onCancelEditTask={handleCancelEditTask}
        onAddNewTask={handleAddNewTask}
      />
    </div>
  );
};

export default AddDayTasksDialog;
