import moment from 'moment';

export const getDatesOfSessions = (hazardsByDay, timezone) => hazardsByDay?.reduce(
    (acc, session) => [
      ...acc,
      {
        id: session.workSessionId,
        date: moment(session.timeStart, 'x').tz(timezone).format('MMM DD YYYY'),
        timeStart: moment(session.timeStart, 'x').tz(timezone).valueOf(),
        timeEnd: moment(session.timeEnd, 'x').tz(timezone).valueOf(),
      },
    ],
    [],
  );
